export const ExpandSVG: React.FC<{
  className?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
}> = ({ className, svgProps }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...svgProps}
    >
      <rect x="0.169922" width="18.3644" height="18" rx="4" fill="#191B20" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23047 11.7026C3.28569 11.5764 3.35932 11.4683 3.48817 11.4143C3.78269 11.2882 4.07721 11.5044 4.07721 11.8287C4.07721 12.2791 4.07721 12.7296 4.07721 13.18C4.07721 13.2881 4.07721 13.4143 4.07721 13.5584C4.13243 13.5044 4.15084 13.4863 4.18765 13.4503C5.07121 12.5854 5.95476 11.7206 6.85673 10.8377C6.96717 10.7296 7.09602 10.6395 7.26169 10.6756C7.59302 10.7296 7.74028 11.09 7.5378 11.3602C7.50098 11.3963 7.46417 11.4323 7.44576 11.4683C6.56221 12.3332 5.67865 13.1981 4.8135 14.0449C4.77669 14.0809 4.75828 14.0989 4.70306 14.153C4.77669 14.153 4.81351 14.153 4.85032 14.153C5.38413 14.153 5.91795 14.153 6.43336 14.153C6.5438 14.153 6.67265 14.189 6.76469 14.2431C7.02239 14.4233 6.98558 14.7836 6.70947 14.9278C6.67265 14.9458 6.63584 14.9638 6.59902 14.9818C5.58662 14.9818 4.5558 14.9818 3.54339 14.9818C3.39614 14.9097 3.3041 14.8197 3.23047 14.6755C3.23047 13.7026 3.23047 12.7116 3.23047 11.7026Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1024 15.0002C11.9735 14.9461 11.8631 14.8741 11.8079 14.7479C11.679 14.4596 11.8999 14.1714 12.2312 14.1714C12.6914 14.1714 13.1516 14.1714 13.6118 14.1714C13.7222 14.1714 13.8511 14.1714 13.9983 14.1714C13.9431 14.1173 13.9247 14.0993 13.8879 14.0633C13.0044 13.1984 12.1208 12.3155 11.2188 11.4507C11.1084 11.3426 11.0164 11.1984 11.0532 11.0363C11.1084 10.7299 11.4581 10.5858 11.7158 10.766C11.7526 10.802 11.8079 10.8381 11.8447 10.8741C12.7282 11.7389 13.6118 12.6038 14.4953 13.4687C14.5322 13.5047 14.5506 13.5227 14.6058 13.5768C14.6058 13.5047 14.6058 13.4687 14.6058 13.4326C14.6058 12.9101 14.6058 12.3876 14.6058 11.8651C14.6058 11.6849 14.661 11.5588 14.8267 11.4507C14.9555 11.3606 15.1028 11.3606 15.2501 11.4326C15.3973 11.5047 15.4709 11.6308 15.4709 11.811C15.4709 12.3876 15.4709 12.9822 15.4709 13.5587C15.4709 13.847 15.4525 14.1533 15.4709 14.4416C15.4893 14.6939 15.3973 14.8921 15.158 15.0002C14.1456 15.0002 13.1148 15.0002 12.1024 15.0002Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59903 3.01802C6.72788 3.07207 6.83832 3.14414 6.89355 3.27027C7.0224 3.55855 6.80151 3.84684 6.47017 3.84684C5.99158 3.84684 5.51299 3.84684 5.0344 3.84684C4.92395 3.84684 4.83191 3.84684 4.68465 3.84684C4.73988 3.90089 4.75828 3.91891 4.7951 3.95495C5.66025 4.80178 6.50699 5.63061 7.37214 6.47744C7.50099 6.60357 7.59303 6.76573 7.5194 6.94591C7.42736 7.21618 7.11443 7.32428 6.87514 7.16212C6.83832 7.12609 6.7831 7.09005 6.74629 7.05402C5.89954 6.22519 5.0344 5.37836 4.18765 4.54953C4.15084 4.5135 4.13243 4.49548 4.07721 4.44143C4.07721 4.5135 4.07721 4.54953 4.07721 4.58557C4.07721 5.10809 4.07721 5.63061 4.07721 6.15312C4.07721 6.26123 4.0404 6.36934 3.98517 6.45943C3.8011 6.71168 3.41454 6.67564 3.28569 6.40537C3.26728 6.36934 3.24888 6.3333 3.23047 6.29727C3.23047 5.30628 3.23047 4.29729 3.23047 3.3063C3.3041 3.16216 3.39614 3.07207 3.5434 3C4.5558 3.01802 5.58662 3.01802 6.59903 3.01802Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1582 3.01807C15.3975 3.12617 15.4712 3.30635 15.4712 3.57662C15.4528 4.45949 15.4712 5.34237 15.4712 6.22524C15.4712 6.44146 15.3239 6.60362 15.1214 6.63965C14.9373 6.67569 14.7349 6.56758 14.6612 6.3874C14.6428 6.31533 14.6244 6.24326 14.6244 6.15317C14.6244 5.63065 14.6244 5.12616 14.6244 4.60364C14.6244 4.5676 14.6244 4.51355 14.6244 4.45949C14.5692 4.51355 14.5508 4.53157 14.514 4.5676C13.6488 5.41444 12.8021 6.24326 11.9369 7.0901C11.8081 7.21623 11.6608 7.30631 11.4583 7.23424C11.1638 7.14415 11.0534 6.81983 11.2375 6.5856C11.2743 6.54957 11.3111 6.51353 11.3479 6.47749C12.1946 5.64867 13.0414 4.80183 13.9065 3.97301C13.9433 3.93698 13.9618 3.91896 14.017 3.86491C13.9433 3.86491 13.9065 3.86491 13.8697 3.86491C13.3359 3.86491 12.8021 3.86491 12.2683 3.86491C12.1578 3.86491 12.0474 3.82887 11.9553 3.77482C11.6976 3.59464 11.7345 3.23428 12.0106 3.09014C12.0474 3.07212 12.0842 3.0541 12.121 3.03608C13.115 3.01807 14.1458 3.01807 15.1582 3.01807Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </svg>
  );
};

export const ToolTipSVG: React.FC<{
  className?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
}> = ({ className = '', svgProps }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.69309e-05 5.97694C-0.0114793 2.67177 2.67181 0 6.02304 0C9.31669 0 11.9885 2.67177 12 5.98845C12.0115 9.32817 9.33972 11.9884 5.97697 11.9999C2.67181 11.9999 0.0115532 9.31665 3.69309e-05 5.97694ZM6.57582 8.9942C6.57582 7.78499 6.57582 6.59881 6.57582 5.41264C6.17275 5.41264 5.79271 5.41264 5.40116 5.41264C5.40116 6.61033 5.40116 7.79651 5.40116 8.9942C5.80423 8.9942 6.19578 8.9942 6.57582 8.9942ZM6.58733 4.19192C6.58733 3.77733 6.58733 3.3973 6.58733 3.01726C6.18427 3.01726 5.79271 3.01726 5.41268 3.01726C5.41268 3.42033 5.41268 3.80036 5.41268 4.19192C5.80423 4.19192 6.18427 4.19192 6.58733 4.19192Z"
        fill="#4F505E"
      />
    </svg>
  );
};

import * as React from 'react';

const FailureIcon = (props) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="6.5" cy="6.5" r="6.5" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 6.5C13 10.0899 10.0899 13 6.5 13C2.91015 13 0 10.0899 0 6.5C0 2.91015 2.91015 0 6.5 0C10.0899 0 13 2.91015 13 6.5ZM9.64461 3.23887C10.0351 3.62939 10.0351 4.26256 9.64461 4.65308L7.91405 6.38364L9.76073 8.23032C10.1513 8.62085 10.1513 9.25401 9.76073 9.64454C9.3702 10.0351 8.73704 10.0351 8.34651 9.64454L6.49983 7.79786L4.65354 9.64415C4.26301 10.0347 3.62985 10.0347 3.23932 9.64415C2.8488 9.25363 2.8488 8.62047 3.23932 8.22994L5.08562 6.38364L3.35544 4.65347C2.96492 4.26294 2.96492 3.62978 3.35544 3.23925C3.74596 2.84873 4.37913 2.84873 4.76965 3.23925L6.49983 4.96943L8.23039 3.23887C8.62092 2.84834 9.25408 2.84834 9.64461 3.23887Z"
      fill="#FF5353"
    />
  </svg>
);

export default FailureIcon;

import { SVGProps } from 'react';
const WalletIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2775_17063)">
      <path
        d="M6.83792 11.5549V4.44572C6.83777 4.21201 6.88268 3.98056 6.97008 3.76462C7.05749 3.54867 7.18567 3.35246 7.3473 3.18721C7.50893 3.02195 7.70084 2.89089 7.91205 2.80153C8.12326 2.71216 8.34962 2.66624 8.5782 2.66639H16.6062V1.77963C16.608 1.30997 16.4274 0.858773 16.1041 0.52515C15.7808 0.191528 15.3411 0.00275954 14.8818 0.000305803H2.32678C1.86256 -0.00843558 1.41373 0.17053 1.07787 0.498296C0.742012 0.826061 0.54627 1.27613 0.533203 1.75065V14.25C0.547149 14.7239 0.743275 15.1731 1.07904 15.5001C1.4148 15.8271 1.86313 16.0056 2.32678 15.9968H14.8784C15.1059 15.9959 15.3309 15.9491 15.5407 15.8592C15.7505 15.7692 15.9409 15.6379 16.101 15.4726C16.261 15.3073 16.3876 15.1114 16.4736 14.896C16.5595 14.6807 16.603 14.4501 16.6017 14.2175V13.3307H8.57367C8.11331 13.3301 7.67199 13.1428 7.34657 12.8099C7.02115 12.4769 6.83822 12.0256 6.83792 11.5549Z"
        fill="#C3C2D4"
      />
      <path
        d="M8.5625 5.08254V10.9155C8.5631 11.0921 8.63197 11.2612 8.7541 11.3861C8.87622 11.511 9.04169 11.5814 9.2144 11.582H16.8354C17.0081 11.5814 17.1736 11.511 17.2957 11.3861C17.4178 11.2612 17.4867 11.0921 17.4873 10.9155V5.08254C17.4867 4.90595 17.4178 4.73678 17.2957 4.61191C17.1736 4.48705 17.0081 4.41663 16.8354 4.41602H9.21213C9.03981 4.41724 8.87493 4.48792 8.75329 4.61272C8.63166 4.73752 8.56309 4.90635 8.5625 5.08254ZM11.7064 9.32857C11.4417 9.34592 11.1781 9.2804 10.9508 9.14075C10.7235 9.0011 10.5431 8.79394 10.4339 8.54687C10.3246 8.2998 10.2915 8.02455 10.3391 7.75779C10.3867 7.49103 10.5126 7.24542 10.7001 7.05368C10.8877 6.86194 11.1279 6.73317 11.3888 6.68454C11.6497 6.6359 11.9189 6.6697 12.1606 6.78143C12.4022 6.89317 12.6048 7.07753 12.7414 7.30996C12.878 7.54239 12.9421 7.81186 12.9251 8.08246C12.9048 8.40614 12.7699 8.71118 12.5456 8.94053C12.3213 9.16987 12.0229 9.30782 11.7064 9.32857Z"
        fill="#C3C2D4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2775_17063">
        <rect
          width="16.9528"
          height="16"
          fill="white"
          transform="translate(0.533203)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default WalletIcon;

import styled from '@emotion/styled';

export const TableAlignerStyles = styled.div`
  /* width: 100%; */
  .table-cell {
    /* padding: 1.3rem 0; */
    /* font-size: 1.6rem; */
    /* font-weight: 400; */
    /* color: var(--text-6); */
    font-family: Relative Pro;
    border-top: none;
    border-bottom: none;
    text-align: center;
  }
`;

const FrontArrow = (props) => (
  <svg
    width={12}
    height={6}
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 3 7 .113v5.774L12 3ZM0 3.5h7.5v-1H0v1Z"
      fill={props.arrowColor ? props.arrowColor : '#99D5EF'}
    />
    {'/[;.'}
  </svg>
);

export default FrontArrow;

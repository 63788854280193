import { SVGProps } from 'react';

export const ResetSVG: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={props.className}
    >
      <path
        d="M0.420657 5C0.420657 2.4709 2.4709 0.420657 5 0.420657H12C14.5291 0.420657 16.5793 2.4709 16.5793 5V13C16.5793 15.5291 14.5291 17.5793 12 17.5793H5C2.4709 17.5793 0.420657 15.5291 0.420657 13V5Z"
        fill="#1C1C28"
        stroke="#2A2A3A"
        strokeWidth="0.841313"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.724 11.2698C11.0026 11.5738 11.2614 11.8779 11.5599 12.182C10.3459 13.1376 8.99249 13.4417 7.57941 12.8553C5.70858 12.0951 4.81298 10.5313 4.75327 8.35942C4.31541 8.35942 3.85766 8.35942 3.4198 8.35942L3.3999 8.3377C4.07659 7.62097 4.73337 6.92597 5.39015 6.23096C6.04693 6.94768 6.6838 7.66441 7.34058 8.35942C6.88283 8.35942 6.42507 8.35942 5.92751 8.35942C5.90761 9.51053 6.26585 10.4662 7.10175 11.1829C8.03717 11.9865 9.49005 12.182 10.724 11.2698Z"
        fill="#C3C2D4"
        className="group-active:animate-rotate"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9201 9.16046C13.3601 9.16046 13.8001 9.16046 14.2601 9.16046L14.2801 9.18002C13.6001 9.82537 12.9401 10.4512 12.2801 11.077C11.6201 10.4316 10.9601 9.78626 10.3201 9.16046C10.8001 9.16046 11.2601 9.16046 11.7601 9.16046C11.7801 8.10444 11.4001 7.22441 10.5401 6.59862C9.38012 5.75771 8.16012 5.81637 6.94012 6.52039C6.68012 6.26616 6.40012 5.99238 6.12012 5.71859C7.22012 4.93635 8.46012 4.62345 9.78012 5.01457C11.8401 5.60126 12.8201 7.04841 12.9201 9.16046Z"
        fill="#C3C2D4"
        className="group-active:animate-rotate"
      />
    </svg>
  );
};

export const LightToolTipSVG: React.FC<{
  className?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
}> = ({ className = '', svgProps }) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.23914e-05 5.24226C-0.0100683 2.34336 2.34339 0 5.28269 0C8.17149 0 10.5148 2.34336 10.5249 5.25236C10.535 8.18156 8.19169 10.5148 5.24229 10.5249C2.34339 10.5249 0.0101331 8.17145 3.23914e-05 5.24226ZM5.76752 7.88864C5.76752 6.82806 5.76752 5.78769 5.76752 4.74732C5.414 4.74732 5.08068 4.74732 4.73725 4.74732C4.73725 5.79779 4.73725 6.83816 4.73725 7.88864C5.09078 7.88864 5.4342 7.88864 5.76752 7.88864ZM5.77762 3.67665C5.77762 3.31302 5.77762 2.9797 5.77762 2.64638C5.4241 2.64638 5.08068 2.64638 4.74735 2.64638C4.74735 2.9999 4.74735 3.33323 4.74735 3.67665C5.09078 3.67665 5.4241 3.67665 5.77762 3.67665Z"
        fill="currentColor"
      />
    </svg>
  );
};
